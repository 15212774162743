import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(to => {
  const appLoading = document.querySelector('#loading-bg') as HTMLElement

  appLoading.style.display = 'none'
})

export default router
