
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import axios from 'axios'
import i18n from '@/i18n/i18n'
import moment from 'moment'

@Component
export default class App extends Vue {
  translationsLoaded = false

  get error(): { name: string, message: string } {
    return this.$store.getters.getError
  }

  mounted(): void {
    axios.get('/translations/common.json')
      .then(({ data }) => {
        this.$i18n.locale = this.$tg.WebApp.initDataUnsafe.user.language_code

        i18n.mergeLocaleMessage('ru', data['ru'])
        i18n.mergeLocaleMessage('en', data['en'])

        this.$i18n.mergeLocaleMessage('ru', data['ru'])
        this.$i18n.mergeLocaleMessage('en', data['en'])

        this.translationsLoaded = true

        moment.locale(this.$i18n.locale)

      //   this.$router.push({
      //     name: 'catalog',
      //   })
      })
  }
}
