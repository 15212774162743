export default {
	install: (vue: any) => {
		vue.$tg = window.Telegram
		vue.prototype.$tg = window.Telegram
	}
}

export type WindowTelegram = {
	WebApp: {
		BackButton: {
			hide: () => void
			show: () => void
			isVisible: boolean
			offClick: (callback: (arg: any) => any) => void
			onClick: (callback: (arg: any) => any) => void
		}
		HapticFeedback: {
			/** Управление вибрацией */
			notificationOccurred: (arg: 'error' | 'success' | 'warning') => void
		}
		MainButton: {
			color: string
			disable: () => void
			enable: () => void
			hide: () => void
			hideProgress: () => void
			isActive: boolean
			isProgressVisible: boolean
			isVisible: boolean
			offClick: (arg: (arg: any) => any) => void
			onClick: (arg: (arg: any) => any) => void
			setParams: (arg: SetMainButtonParams) => any
			setText: (arg: string) => any
			show: () => void
			showProgress: () => void
			text: string
			textColor: string
		}
		backgroundColor: string
		/** Закрывает Web view */
		close: () => void
		colorScheme: 'light' | 'dark'
		/** Метод, который отключает диалоговое окно подтверждения, когда пользователь пытается закрыть веб-приложение. */
		disableClosingConfirmation: () => void
		/** Метод, который включает диалоговое окно подтверждения, когда пользователь пытается закрыть веб-приложение. */
		enableClosingConfirmation: () => void
		/** Метод, который расширяет веб-приложение до максимально доступной высоты. Чтобы узнать, развернуто ли веб-приложение на максимальную высоту, обратитесь к значению параметра Telegram.WebApp.isExpanded. */
		expand: () => void
		headerColor: string
		/** Строка с необработанными данными, переданными в веб-приложение, удобная для проверки данных. */
		initData: string
		/**Объект с входными данными, переданными в веб-приложение.
		 * ВНИМАНИЕ: Данным из этого поля нельзя доверять. Вы должны использовать данные из initData только на сервере бота и только после их проверки
		 * */
		initDataUnsafe: initDataUnsafe
		/**Флаг, показывающий активность диалогового окна при закрытии Web View*/
		isClosingConfirmationEnabled: boolean
		/**Флаг, показывающий развернуто ли Web View на максимум*/
		isExpanded: boolean
		/***/
		isVersionAtLeast: (version: string) => boolean
		/**Метод, который удаляет ранее установленный обработчик событий.*/
		offEvent: <K extends keyof EventTypes>(names: K | K[], handler: (args: EventTypes[K]) => void | unknown) => any
		/**Метод, который устанавливает обработчик событий приложения*/
		onEvent: <K extends keyof EventTypes>(names: K | K[], handler: (args: EventTypes[K]) => void | unknown) => any
		/**Метод, открывающий счет-фактуру с использованием URL-адреса ссылки*/
		openInvoice: any
		/**Метод, который открывает ссылку Telegram внутри приложения Telegram. Веб-приложение будет закрыто.*/
		openLink: (url: string) => void
		/**Метод, который открывает ссылку телеграммы внутри приложения Telegram. Веб-приложение будет закрыто.*/
		openTelegramLink: (url: string) => void
		/**
		 * Метод, который информирует приложение Telegram о том, что веб-приложение готово к отображению.
		 * Рекомендуется вызывать этот метод как можно раньше, как только будут загружены все необходимые элементы интерфейса.
		 * После вызова этого метода заполнитель загрузки скрывается и отображается веб-приложение.
		 * Если метод не вызывается, заполнитель будет скрыт только при полной загрузке страницы.
		 * */
		ready: () => void
		/**Метод, который устанавливает цвет фона приложения в формате #RRGGBB или вместо этого вы можете использовать ключевые слова bg_color,secondary_bg_color.*/
		setBackgroundColor: (color: string) => void
		/**Метод, который задает цвет заголовка приложения.
		 * Вы можете передать только Telegram.WebApp.themeParams.bg_color или Telegram.WebApp.themeParams.secondary_bg_color в качестве цвета или вместо этого можете использовать ключевые слова bg_color,secondary_bg_color.*/
		setHeaderColor: (color: string) => void
		/**Метод, который показывает сообщение в простом предупреждении с кнопкой «Закрыть».
		 * Если был передан необязательный параметр обратного вызова, функция обратного вызова будет вызываться при закрытии всплывающего окна.*/
		showAlert: (message: string, callback?: (args: boolean) => any) => void
		/**Метод, который показывает сообщение в простом окне подтверждения с кнопками «ОК» и «Отмена».
		 * Если был передан необязательный параметр обратного вызова, функция обратного вызова будет вызываться при закрытии всплывающего окна, а первым аргументом будет логическое значение, указывающее, нажал ли пользователь кнопку «ОК».*/
		showConfirm: (message: string, callback?: (args: any) => any) => void
		/**Метод, который показывает собственное всплывающее окно, описанное аргументом params типа PopupParams. Веб-приложение получит событие popupClosed, когда всплывающее окно будет закрыто. Если был передан необязательный параметр обратного вызова, будет вызвана функция обратного вызова, и идентификатор поля нажатой кнопки будет передан в качестве первого аргумента.*/
		showPopup: (params: PopupParams, callback?: (args: any) => any) => void
		/**Объект, содержащий текущие настройки темы, используемые в приложении Telegram.*/
		themeParams: ThemeParams
		/**Версия Bot API, доступная в приложении Telegram пользователя.*/
		version: string
		/**Текущая высота видимой области веб-приложения. Также доступно в CSS как переменная var(--tg-viewport-height).*/
		viewportHeight: number
		/**Высота видимой области веб-приложения в его последнем стабильном состоянии. Также доступно в CSS как переменная var(--tg-viewport-stable-height).*/
		viewportStableHeight: number
		sendData: (data: any) => void
	}
}

type ThemeParams = {
	bg_color: string
	text_color: string
	hint_color: string
	link_color: string
	button_color: string
	button_text_color: string
	secondary_bg_color: string
}

export type initDataUnsafe = {
	/** Необязательно. Уникальный идентификатор сеанса веб-приложения, необходимый для отправки сообщений с помощью метода запроса веб-приложения answer. */
	query_id: string
	/** Необязательно. Объект, содержащий данные о текущем пользователе. */
	user:	WebAppUser
	/**Необязательно. Объект, содержащий данные о партнере по чату текущего пользователя в чате, где бот был запущен через меню вложения. Возвращается только для приватных чатов и только для веб-приложений, запущенных через меню вложений.*/
	receiver: 	WebAppUser
	/**Необязательно. Объект, содержащий данные о чате, в котором бот был запущен через меню вложения. Возвращено для супергрупп, каналов и групповых чатов – только для веб-приложений, запускаемых через меню вложений.*/
	chat:	WebAppChat
	/**
	 * Необязательно. Значение параметра startattack, передаваемое по ссылке. Возвращается только для веб-приложений при запуске из меню вложений по ссылке.
	 * Значение параметра start_param также будет передано в GET-параметре tgWebAppStartParam, чтобы веб-приложение могло сразу загрузить правильный интерфейс.
	 * */
	start_param: string
	/**Необязательно. Время в секундах, по истечении которого сообщение может быть отправлено с помощью метода запроса answer WebApp.*/
	can_send_after:	number
	/**Время Unix, когда была открыта форма.*/
	auth_date: number
	/**Хэш всех переданных параметров, который сервер ботов может использовать для проверки их достоверности.*/
	hash:	string
}

export type WebAppUser = {
	/** Уникальный идентификатор пользователя или бота. Это число может содержать более 32 значащих битов, и некоторые языки программирования могут испытывать трудности / молчаливые дефекты при его интерпретации. Он содержит не более 52 значащих битов, поэтому для хранения этого идентификатора безопасно использовать 64-разрядное целое число или тип с плавающей запятой двойной точности. */
	id:	number
	/** Необязательно. Верно, если этот пользователь является ботом. Возвращает только в поле получателя. */
	is_bot:	boolean
	/** Имя пользователя или бота. */
	first_name:	string
	/** Необязательно. Фамилия пользователя или бота. */
	last_name: string
	/** Необязательно. Имя пользователя или бота. */
	username:	string
	/** Необязательно. Языковой тег IETF языка пользователя. Возвращает только в пользовательском поле. */
	language_code: string
	/** Необязательно. Верно, если этот пользователь является пользователем Telegram Premium */
	is_premium:	boolean
	/** Необязательно. URL-адрес фотографии профиля пользователя. Фотография может быть в форматах .jpeg или .svg. Возвращается только для веб-приложений, запущенных из меню вложений. */
	photo_url:	string
}

export type WebAppChat = {
	/**Уникальный идентификатор для этого чата. Это число может содержать более 32 значащих битов, и некоторые языки программирования могут испытывать трудности / молчаливые дефекты при его интерпретации. Но он содержит не более 52 значащих битов, поэтому 64-разрядное целое число со знаком или тип с плавающей запятой двойной точности безопасны для хранения этого идентификатора.*/
	id: number
	/**Тип чата, может быть “группа”, “супергруппа” или “канал”*/
	type: string
	/**Название чата*/
	title: string
	/**Необязательно. Имя пользователя в чате*/
	username: string
	/**Необязательно. URL-адрес фотографии из чата. Фотография может быть в форматах .jpeg или .svg. Возвращается только для веб-приложений, запущенных из меню вложений.*/
	photo_url: string
}

type SetMainButtonParams = {
	/** Текст кнопки */
	text?: string
	/** Цвет кнопки */
	color?: string
	/** Цвет текста кнопки */
	text_color?: string
	/** Активность кнопки */
	is_active?: boolean
	/** Видимость кнопки */
	is_visible?: boolean
}

type EventTypes = {
	/**Происходит всякий раз, когда настройки темы изменяются в приложении Telegram пользователя (включая переключение в ночной режим).*/
	themeChanged: void
	/**
	 * Происходит при изменении видимой части веб-приложения.
	 * eventHandler получает объект с единственным полем isStateStable.
	 * Если isStateStable имеет значение true, изменение размера веб-приложения завершено.
	 * Если оно равно false, изменение размера продолжается (пользователь разворачивает или сворачивает веб-приложение или воспроизводится анимированный объект).
	 * Текущее значение высоты видимого раздела доступно в this.viewportHeight.
	 * */
	viewportChanged: { isStateStable: boolean }
	/**Происходит при нажатии основной кнопки. eventHandler не получает никаких параметров*/
	mainButtonClicked: void
	/**Происходит при нажатии кнопки "Назад". eventHandler не получает никаких параметров*/
	backButtonClicked: void
	/**Происходит при нажатии пункта Настройки в контекстном меню.eventHandler не получает никаких параметров.*/
	settingsButtonClicked: void
	/**
	 * Происходит при закрытии открытого счета.
	 * eventHandler получает объект с двумя полями: url — указанная ссылка на счет и статус — один из статусов счета:
	 * - оплачен – счет успешно оплачен,
	 * - отменен – пользователь закрыл этот счет без оплаты,
	 * - failed – пользователь пытался заплатить, но платеж не прошел,
	 * - pending – платеж все еще обрабатывается. Бот получит служебное сообщение об успешном платеже, когда платеж будет успешно оплачен.
	 */
	invoiceClosed: { url: string, status: invoiceStatus }
	/**
	 * Происходит, когда открытое всплывающее окно закрывается.eventHandler получает объект с единственным полем button_id — значение поля id нажатой кнопки.
	 * Если ни одна кнопка не была нажата, поле button_id будет пустым.
	 * */
	popupClosed: { button_id: number }
}

type PopupParams = {
	title: string
	message: string
	buttons: Array<PopupButton>
}

type PopupButton = {
	id: string
	type: PopupButtonType
	/**Текст, который будет отображаться на кнопке, 0-64 символа. Требуется, если тип задан по умолчанию или деструктивный. Неактуально для других типов*/
	text: string
}

export enum PopupButtonType {
	/**кнопка со стилем по умолчанию*/
default = 'default',
	/**кнопка с локализованным текстом «ОК»*/
ok = 'ok',
	/**кнопка с локализованным текстом «Закрыть»*/
close = 'close',
	/**кнопка с локализованным текстом «Отмена»*/
cancel = 'cancel',
	/**кнопка со стилем, указывающим на деструктивное действие (например, «Удалить», «Удалить» и т. д.)*/
destructive = 'destructive',
}

enum invoiceStatus {
	paid = 'paid',
	cancelled = 'cancelled',
	failed = 'failed',
	pending = 'pending',
}
