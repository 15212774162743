import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './messages'

Vue.use(VueI18n)

let lang = navigator.language

const cacheLocale = localStorage.getItem('locale')

if (cacheLocale) {
	lang = cacheLocale
} else {
	if (lang !== 'ru-RU') {
		lang = 'en'
	} else {
		lang = 'ru'
	}
}

const i18n = new VueI18n({
	locale                : lang,
	formatFallbackMessages: true,
	messages,
	silentTranslationWarn: true,
})

export default i18n
