import Vue from 'vue'
import Vuex, { Commit } from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error: null
  },
  getters: {
    getError(state: any) {
      return state.error
    }
  },
  mutations: {
    setError(state, error) {
      state.error = error
    }
  },
  actions: {
    setError({ commit }: { commit: Commit }, error: { name: string, message: string }) {
      commit('setError', error)
    }
  },
  modules: {
  }
})
