import { RouteConfig } from "vue-router"

export const routes: Array<RouteConfig> = [
	{
		path: '/product',
		name: 'product',
		component: () => import('../views/ProductPage.vue')
	},
	{
		path: '/catalog',
		name: 'catalog',
		component: () => import('../views/Catalog.vue')
	},
	{
		path: '/showcase',
		name: 'showcase',
		component: () => import('../views/Showcase.vue')
	},
	{
		path:'/:botId/profile/:profileId',
		name: 'profile',
		component: () => import('../views/Profile.vue')
	},
	{
		path: '/notice-view',
		name: 'NoticeView',
		component: () => import('../components/profile/NoticeView.vue')
	},
	{
		path: '/buttons-constructor',
		name:'buttons_constructor',
		component: () => import('../views/ButtonsConstructor.vue')
	},
	{
		path: '/test',
		name: 'test',
		component: () => import('../views/Test.vue')
	}
]
