import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'
import Antd from 'ant-design-vue'
import i18n from './i18n/i18n'

import TG from './logic/TGUtil'
import Notify from './logic/AntNotify'

import 'ant-design-vue/dist/antd.css'
import './assets/index.scss'

Vue.config.productionTip = false

Vue.use(Antd)

Vue.use(TG)
Vue.use(Notify)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
