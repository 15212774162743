import { notification } from 'ant-design-vue'

export enum NotifyType {
	Success = 'success',
	Info = 'info',
	Warning = 'warning',
	Error = 'error',
}

export default {
	install: (vue: any) => {
		const func = ({ title, message, type }: { title: string, message: string, type: NotifyType }) => {
			notification[type]({
				message: title,
				description: message,
				placement: 'bottomRight'
			})
		}

		vue.$notify = func
		vue.prototype.$notify = func
	}
}
